
$( document ).ready(function() {

    if (window.location.hash === '#services') {
        // Remove the preloader if #services is in the URL
        $('.preloader').hide();
        // Scroll to the #services section
        $('html, body').animate({
            scrollTop: $('#services').offset().top
        }, 'slow');
    }

    // #1 Sticky header

    let lastScrollTop = 0;
    let header = $('.header');
    let ticking = false;

    function updateHeader() {
        let currentScroll = $(window).scrollTop();

        if (currentScroll > lastScrollTop) {
            header.addClass('scrolling').removeClass('default');
            if (currentScroll > 50) { // Adjust this value as needed
                header.addClass('hidden');
            }
        } else {
            header.removeClass('hidden');
            if (currentScroll === 0) {
                header.addClass('default').removeClass('scrolling');
            }
        }

        lastScrollTop = currentScroll;
        ticking = false;
    }

    $(window).scroll(function() {
        if (!ticking) {
            window.requestAnimationFrame(function() {
                updateHeader();
            });
            ticking = true;
        }
    });

    // Additional handling for Safari's initial scroll behavior
    $(window).on('load', function() {
        if ($(window).scrollTop() === 0) {
            header.addClass('default').removeClass('scrolling');
        }
    });

    // #2 Active tab video play

    var $isServices = $('.home-services');
    if ($isServices.length) {

        let autoChangeInterval; // Declare a variable to hold the interval
        let currentIndex = 0; // Declare a variable to keep track of the current index

        function restartVideo(videoElement) {
            videoElement.currentTime = 0;
            videoElement.play(); // Ensure video starts playing after restart
        }

        function restartVideoOnNavLinkClick() {
            // Get all nav links
            let navLinks = document.querySelectorAll('.nav-link');

            // Loop through each nav link
            navLinks.forEach((navLink, index) => {
                // Add click event listener to each nav link
                navLink.addEventListener('click', function() {
                    // Get the corresponding tab pane and video element
                    let tabID = navLink.getAttribute('data-bs-target');
                    let tabPane = document.querySelector(tabID);
                    let video = tabPane.querySelector('video');

                    // Check if the video element exists and restart it
                    if (video) {
                        restartVideo(video);
                    }

                    // Clear the existing interval when a tab is manually clicked
                    clearInterval(autoChangeInterval);

                    // Start the automatic tab change from the next tab index after a short delay to ensure it doesn't conflict with manual click
                    currentIndex = index;
                    setTimeout(autoChangeTab, 100);
                });
            });
        }

        function autoChangeTab() {
            // Get all nav links
            let navLinks = document.querySelectorAll('.nav-link');

            autoChangeInterval = setInterval(() => {
                currentIndex = (currentIndex + 1) % navLinks.length; // Move to the next tab index

                // Trigger click on the next nav link after 8 seconds
                navLinks[currentIndex].click();
            }, 7000); // Change tab every 8 seconds (8000 milliseconds)
        }

        // Intersection Observer to trigger the tab change and video play
        const homeServicesSection = document.querySelector('.home-services');

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (window.innerWidth >= 768) {
                        autoChangeTab(); // Start the automatic tab change and video play
                    } else {
                        // For mobile devices, directly start video playback
                        let tabPane = entry.target; // Assuming tabPane is the target being intersected
                        let video = tabPane.querySelector('video');
                        if (video) {
                            restartVideo(video);
                        }
                    }
                    observer.disconnect(); // Disconnect the observer after triggering
                }
            });
        });

        observer.observe(homeServicesSection);

        restartVideoOnNavLinkClick();
    }

    // #3 Swiper testimonials

    var swiper = new Swiper(".swiper-testimonials", {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
        speed: 1000,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                slidesPerGroup: 1,
            },
            // when window width is >= 769px
            769: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            }
        }
    });

    // #4 Hamburger menu

    $('.hamburger a').on('click', function (){
       $('.header').toggleClass('opened');
       $('body').toggleClass('overflow');
    });

    // #5 Slider works

    var swiper = new Swiper(".swiper-works", {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
            },
            // when window width is >= 769px
            769: {
                slidesPerView: 2,
            }
        }
    });

    // #6 Showreel modal

    // On clicking an action button
    $('.home-showreel .action').on('click', function () {
        var modalId = $(this).data('modal');  // Get the modal ID from data attribute
        var $modal = $('#' + modalId);  // Select the modal based on the ID

        if ($modal.length > 0) {
            var playerElement = $modal.find('iframe');  // Get the iframe inside the modal
            var player = new Vimeo.Player(playerElement);  // Initialize the Vimeo player

            $('body').addClass('overflow');
            $('header').addClass('hide');
            $('.home-showreel').addClass('zindex');
            $modal.addClass('active');  // Activate the corresponding modal
            player.play();  // Play the video

            // Close modal when clicking the close button
            $modal.find('.close-modal').on('click', function () {
                closeModal($modal, player);
            });

            // Close modal when pressing the Esc key
            $(document).on('keydown', function(evt) {
                if (evt.keyCode == 27) {  // ESC key
                    closeModal($modal, player);
                }
            });
        }
    });

    function closeModal($modal, player) {
        $('body').removeClass('overflow');
        $('header').removeClass('hide');
        $('.home-showreel').removeClass('zindex');
        $modal.removeClass('active');
        player.pause();  // Pause the video
    }
    
    // #7 Highlight blog href

    jQuery(window).scroll(function(){
        var scrollTop = jQuery(document).scrollTop();
        var anchors = jQuery('body').find('.ez-toc-section');

        offset_1 = 150;
        offest_2 = 1;

        for (var i = 0; i < anchors.length; i++){

            if (scrollTop > jQuery(anchors[i]).offset().top - offset_1 && scrollTop < jQuery(anchors[i]).offset().top + jQuery(anchors[i]).height() - offest_2) {
                jQuery('nav ul li a').removeClass('active');
                jQuery('nav ul li a[href="#' + jQuery(anchors[i]).attr('id') + '"]').addClass('active');
            }

        }
    });

    // #7 Swiper blog related

    var swiper = new Swiper(".swiper-related", {
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: true,
        freeMode: true,
        grabCursor: true,
        mousewheelControl: true,
        freeModeMomentum: false,
        direction: 'horizontal',
        slidesPerGroup: 1,
        effect: 'slide',
        speed: 10000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false
        },
        pauseOnMouseEnter: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                autoplay: false
            },
            // when window width is >= 769px
            769: {
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false
                },
            }
        }
    });

    // #9 Swiper home logos

    var swiper = new Swiper(".swiper-logos", {
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: true,
        freeMode: true,
        grabCursor: true,
        mousewheelControl: true,
        freeModeMomentum: false,
        direction: 'horizontal',
        slidesPerGroup: 1,
        effect: 'slide',
        speed: 5500,
        autoplay: {
            delay: 0,
            disableOnInteraction: false
        },
        pauseOnMouseEnter: true,

    });

    // #10 Cookies appear

    window.addEventListener('scroll', function() {
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;
        var hiddenDiv = document.getElementById('cookie-notice');

        if (scrollPosition > 500) {
            hiddenDiv.style.opacity = '1';
        } else {
            hiddenDiv.style.opacity = '0';
        }
    });




});
$( document ).ready(function() {

    // Scroll to

    $('#scroll-to').on('click', (e)=>{ gsap.to(window, {scrollTo:{y:$("#our-works").offset().top - 270}, duration: .6, ease:'power1.inOut'}); }) // scrollTo requires the ScrollTo plugin (not to be confused w/ ScrollTrigger)

    // Scale animation

    if ($(window).innerWidth() <= 768) {

    } else {
        var controller = new ScrollMagic.Controller();
        var scaleElements = document.querySelectorAll(".scale-animation");

        scaleElements.forEach(function(element) {
            var transformTween = TweenMax.to(element, 1, {
                transform: 'scale(1)',
                ease: Power0.easeNone // Change the easing function if needed
            });

            var borderRadiusTween = TweenMax.to(element, 0.5, {
                borderRadius: 0,
                ease: Power0.easeNone // Change the easing function if needed
            });

            var scene = new ScrollMagic.Scene({
                triggerElement: element,
                offset: -500,
                duration: 600,
            })
                .setTween(transformTween)
                // .addIndicators()
                .addTo(controller);

            var borderRadiusScene = new ScrollMagic.Scene({
                triggerElement: element,
                offset: -500,
                duration: 700, // Duration for borderRadiusTween
            })
                .setTween(borderRadiusTween)
                // .addIndicators()
                .addTo(controller);
        });
    }


});